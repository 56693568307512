import React, { useState } from "react";
import styled from "styled-components";
// Components
import ProjectBox from "../Elements/ProjectBox";

import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import ServiceList from "../data/serviceList";

export default function Services() {
  const [open, setOpen] = useState(false)
  const [title, setTitle] = useState("")
  const [detail, setDetail] = useState("")
  const [image, setImage] = useState("")

  const onOpenModal = (title, detail, image) => {
    setOpen(true);
    setTitle(title)
    setDetail(detail)
    setImage(image)
  };

  const onCloseModal = () => {
    setOpen(false);
  };

  return (
    <Wrapper id="services">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold" style={{ marginTop: "50px" }}>Hizmetlerimiz</h1>
            <p className="font16">
            Farklı sektörlerdeki şirketlere ihtiyaçları doğrultusunda Network & Santral Desteği, CRM Desteği, Yazılım Geliştirme, Proje Yönetimi,
              <br />
              Web Hosting ve Siber Güvenlik Desteğini de kapsayacak hizmetler sunmaktadir.
            </p>
          </HeaderInfo>
          <div className="row textCenter">
            {
              ServiceList.map((item, index) => {
                return (<div onClick={() => onOpenModal(item.title, item.detail, item.image)} className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <ProjectBox
                    img={item.image}
                    title={item.title}
                    text={item.description}
                  />
                </div>)
              })
            }

          </div>
          <Modal open={open} onClose={onCloseModal} center>
            <div style={{ display: "flex" }}>
              <img className="radius8" src={image} alt="project" width="100" height="80"></img>
              <h2 style={{marginLeft:"20px",marginTop:"40px"}}>{title}</h2>
            </div>
            <div className='modal' dangerouslySetInnerHTML={{ __html: detail }} />
          </Modal>
          <div className="row flexCenter">
            <div style={{ margin: "50px 0", width: "200px" }}>

            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
