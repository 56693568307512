import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";

export default function Contact() {

  const getCurrentYear = () => {
    return new Date().getFullYear();
  }

  return (
    <Wrapper>
      <div className="purpleBg">
        <div className="container">
          <InnerWrapper className="flexSpaceCenter" style={{ padding: "30px 0" }}>
            <Link className="flexCenter animate pointer" to="home" smooth={true} offset={-80}>
              <img src="assets/img/dlc-light-logo.png" alt="light-logo" width="130px"/><br />
            </Link>
            <div>
              <a className="whiteColor" style={{ padding: "10px 10px", display: "flex", color:"#fff" }} href="mailto:support@dlc.software">
                <i className="fas fa-envelope" style={{ marginRight: "10px" }} /><span> support@dlc.software</span>
              </a>
            </div>

            <Link className="whiteColor animate pointer font15 to-up" to="home" smooth={true} offset={-80}>
              <i className="fas fa-chevron-up whiteColor"></i>
            </Link>
          </InnerWrapper>
        </div>
      </div>
      <div className="darkBg">
        <div className="container">
          <StyleP className="whiteColor font13">
            © {getCurrentYear()} - <span className="semiBold font13">DLC Yazılım ve Danışmanlık LTD STI.</span> Tüm Hakları Saklıdır.
          </StyleP>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
const InnerWrapper = styled.div`
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;
const StyleP = styled.p`
    margin: 0;
    padding: 20px 0;
`;