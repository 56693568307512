import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
// Components
import FullButton from "../Buttons/FullButton";
// Assets
import AddImage1 from "../../assets/img/about-1.jpg";
import AddImage2 from "../../assets/img/about-2.jpg";
import AddImage3 from "../../assets/img/about-3.png";
import AddImage4 from "../../assets/img/about-4.jpg";
import WhoAreWe from "../../assets/img/who-are.jpg"

export default function About() {
    return (
        <Wrapper id="about">
            <div className="whiteBg" style={{ padding: "0" }}>
                <div className="lightBg">
                    <div className="container">
                        <Advertising className="flexSpaceCenter">
                            <AddLeft>
                                <h4 className="font14 semiBold">DLC Yazılım ve Danışmanlık</h4>
                                <h2 className="font40 extraBold">Hakkımızda</h2>
                                <p className="font16">
                                    <b>DLC Yazılım ve Danışmanlık</b>; iş süreçleri yönetimi ve dış kaynak kullanımı, organizasyonel verim ve iş zekası kullanımı konularında anahtar teslimi projeler üreten, işinde uzman bir firmadır.
                                </p>
                                <p className="font16">
                                    Kaliteli hizmeti, proje başarıları, sunulan ürün ve hizmette farklılıkları ile global marka olmak; müşterilerimize kattığımız değeri sürekli ve belirgin bir biçimde arttırmak için çalışmaktayız...
                                </p>
                                <ButtonsRow style={{ marginTop: "30px" }}>
                                    <Link className="service-button" style={{ width: "210px" }} to={"services"} spy={true} smooth={true} offset={-80}>
                                        <FullButton title="Hizmetleri Görüntüle" />
                                    </Link>
                                </ButtonsRow>
                            </AddLeft>
                            <AddRight>
                                <AddRightInner>
                                    <div className="flexNullCenter">
                                        <AddImgWrapp1 className="flexCenter">
                                            <img src={AddImage1} alt="office" />
                                        </AddImgWrapp1>
                                        <AddImgWrapp2>
                                            <img src={AddImage2} alt="office" />
                                        </AddImgWrapp2>
                                    </div>
                                    <div className="flexNullCenter">
                                        <AddImgWrapp3>
                                            <img src={AddImage3} alt="office" />
                                        </AddImgWrapp3>
                                        <AddImgWrapp4>
                                            <img src={AddImage4} alt="office" />
                                        </AddImgWrapp4>
                                    </div>
                                </AddRightInner>
                            </AddRight>
                        </Advertising>
                    </div>
                    <div className="container">
                        <div className="row who-are-we-row">
                            <div className="col-lg-6 col-md-6 who-are-we">
                                <img src={WhoAreWe} alt="who-are-we" width="100%" />
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <h2 className="font40 extraBold">Biz Kimiz ?</h2>
                                <p><b>DLC Yazılım ve Danışmanlık</b>, 2020 yılında kurulmuş yeni nesil bir bilgi teknolojileri şirketidir.</p>
                                <p>2023 yılında Cihan Akyüz tarafından devralınmıştır. Cihan Akyüz, Almanya' da Dresden Teknoloji Üniversitesinden mezun
                                    olduktan sonra İstanbul’a dönmüş, 2 yıl bilgisayar programcılığı eğitimi verdikten sonra bilgi ve tecrübelerini ticari
                                    anlamda değerlendirmek için <b>DLC Yazılım ve Danışmanlık</b> firmasını devralarak ticari hayata adım atmıştır.</p>
                                <p>Şirketimiz tecrübeli ve uzman kadrosuyla hep yanınızdadır. Türkiye’nin birçok yerinde kullanılan programlarımız ve
                                    destek verdiğimiz müşterilerimiz ile uzun soluklu çalışma yaparak kararlı adımlarla büyümeye devam ediyoruz.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="container misyon-container" style={{ marginTop: "100px", paddingBottom: "75px" }}>
                        <h2 className="font40 extraBold textCenter">Misyon ve Vizyonumuz</h2>
                        <div className="row" style={{ marginTop: "50px" }}>
                            <div className="col-lg-6 col-md-6">
                                <h2 className="textCenter">Misyonumuz</h2>
                                <p style={{ marginTop: "0" }}>Misyonumuz, kurumsal tüm sektörlerden firmalar için iş analizi yaparak, firmaların dinamiklerinde bulunan insan
                                    gücünü azaltmak ve maksimum verim elde etmek amacıyla yazılım teknolojileri ve algoritmalar kullanarak çözümler
                                    üretmektir.</p>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <h2 className="textCenter">Vizyonumuz</h2>
                                <p style={{ marginTop: "0" }}>Vizyonumuz, ileri teknolojinin şirketlerin gelişiminde ve rekabet güçlerinin artırılmasında en güçlü kaldıraç olacağı
                                    gelecekte, özel yazılım çözümleri ve danışmanlık hizmetleri kapsamındaki bütüncül hizmetlerimizle müşterilerimizin bilgi
                                    teknolojisinin getirilerinden en üst düzeyde yararlanmalarını sağlayan öncü marka olmaktır.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
}

const Wrapper = styled.section`
  width: 100%;
`;
const Advertising = styled.div`
  margin: 80px 0;
  padding: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto
  }
`;
const AddLeft = styled.div`
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  position: absolute;
  top: -70px;
  right: 0;
  @media (max-width: 860px) {
    width: 80%;
    position: relative;
    order: 1;
    top: -40px;
  }
`;
const AddRightInner = styled.div`
  width: 100%;
`;
const AddImgWrapp1 = styled.div`
  width: 48%;
  margin: 0 6% 10px 6%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp2 = styled.div`
  width: 30%;
  margin: 0 5% 10px 5%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp3 = styled.div`
  width: 20%;
  margin-left: 40%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp4 = styled.div`
  width: 30%;
  margin: 0 5%auto;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;